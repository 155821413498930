var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-list',{attrs:{"heading":_vm.heading,"headings":_vm.getHeadings(),"sort":[
    { prop: 'title', type: 'translation' },
    { prop: 'building', prop2: 'name', type: 'level2' },
    { prop: 'progress', prop2: 'progressPercentage', type: 'level2' }
  ],"items":_vm.courses,"search_selectors":[
    {prop: 'title', type: 'translation'},       
    { prop: 'building', prop2: 'name', type: 'level2' }
  ]},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":_vm.getTranslation(props.item.title),"infoHtml":_vm.getTranslation(props.item.description)}}),_c('ui-list-data',{attrs:{"type":"tag","text":props.item.building.name}}),(_vm.building)?[(typeof _vm.getPercentage(props.item) === 'number')?_c('ui-list-data-progress',{attrs:{"percentage":_vm.getPercentage(props.item),"progressDetails":props.item.progress}}):_vm._e(),(_vm.getPercentage(props.item) === false)?_c('ui-list-data',{attrs:{"text":_vm.$t('labels.course.no_users_assigned')}}):_vm._e()]:_vm._e(),_c('ui-list-data',{attrs:{"small":""}},[_c('auth-checker',{attrs:{"accessRoles":['SuperAdmin']}},[(!props.item.published)?_c('ui-link',{staticClass:"publish_button",attrs:{"type":"success","size":"small","uppercase":"","disabled":props.item.locked},on:{"click":function($event){return _vm.onPublish(props.item)}}},[_vm._v(_vm._s(_vm.$t('actions.publish')))]):_vm._e(),(props.item.published)?_c('span',[_vm._v(_vm._s(_vm.$d( new Date(props.item.published), 'short', _vm.$i18n.locale === 'no' ? 'no' : 'en' )))]):_vm._e(),_c('ui-link',{staticClass:"ml-2",attrs:{"type":"normal","size":"small","uppercase":"","disabled":props.item.locked},on:{"click":function($event){return _vm.$router.push({
              name: 'courses-show',
              params: { id: props.item.id }
            })}}},[_vm._v(_vm._s(_vm.$t('labels.course.edit.content')))]),_c('ui-link',{staticClass:"ml-2",attrs:{"type":"normal","size":"small","uppercase":"","disabled":props.item.locked},on:{"click":function($event){return _vm.$router.push({
              name: 'courses-edit',
              params: { id: props.item.id }
            })}}},[_vm._v(_vm._s(_vm.$t('labels.course.edit.head')))]),(props.item.locked)?_c('ui-link',{staticClass:"ml-2 lock_button",attrs:{"type":"secondary","size":"small","uppercase":""},on:{"click":function($event){return _vm.onUnlock(props.item)}}},[_c('Unlocked',{staticClass:"svg-icon"}),_vm._v(" "+_vm._s(_vm.$t('actions.unlock'))+" ")],1):_vm._e(),(!props.item.locked)?_c('ui-link',{staticClass:"ml-2 lock_button",attrs:{"type":"secondary","size":"small","uppercase":""},on:{"click":function($event){return _vm.onLock(props.item)}}},[_c('Locked',{staticClass:"svg-icon"}),_vm._v(" "+_vm._s(_vm.$t('actions.lock'))+" ")],1):_vm._e()],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }